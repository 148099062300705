<template>
  <div style="text-align:center">
    <br>
    <br>
    <img style="width: 50%; height: auto" src="../public/img/ezmoney.png">
  </div>
</template>

<script>

export default {
  name: "App",
  data() {
            return {
               
            }
        },
  created() {
      document.body.style.backgroundColor = "#002F6C"
  },
  methods: {
    
  },
  
};
</script>

<style>

</style>