import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyCGVK2PB3aQxOh6942UWMl7DDCHlZjbO2Y",
  authDomain: "adabands-ccada.firebaseapp.com",
  projectId: "adabands-ccada",
  storageBucket: "adabands-ccada.appspot.com",
  messagingSenderId: "266623277311",
  appId: "1:266623277311:web:e9101e8cebe572537314ca",
  measurementId: "G-21X53FNSEV"
}

const firebaseApp = firebase.initializeApp(firebaseConfig);

export const db = firebaseApp.firestore();